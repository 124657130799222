import React from "react";
import "./footer.scss";
import { FaTelegram, FaVk, FaYoutube, FaSearch } from "react-icons/fa";
import Logo from "../../assets/logo-footer.png";
// import Logwo from '../../assets/solgasie.doc'
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.includes("/admin-panel");
  console.log(location.pathname);

  return (
    <div className={`footer ${isAdminRoute && 'footer__hide'}`}>
      <div className='footer-main'>
      <div className="upper-block">
        <section className="footer-logo">
          <Link to="/">
            <img
              src={Logo}
              alt="Логотип НЦПТИ белый"
              className="footer-logo__img"
            />
          </Link>
          {/*<input type="search" placeholder='Поиск....'/>*/}
        </section>
        <section className="footer-menu">
          <Link to="news">События недели</Link>
          <Link to="forums">Наши мероприятия</Link>
          <Link to="education">Очное обучение</Link>
          <Link to="online-courses">Онлайн-курсы</Link>
          <Link to="materialy">Материалы</Link>
          <Link to="obzor">Обзор.НЦПТИ</Link>
        </section>
        <section className="footer-contacts">
          <a href="tel:88632012822">+7(863)201-28-22</a>
          <a href="mailto:info@ncpti.ru">info@ncpti.ru</a>
          <p className="footer-p__contacts">344011, г. Ростов-на-Дону</p>
          <p className="footer-p__contacts">ул. Города Волос, д.6, а/я 488</p>
          <div className="footer__social-icons">
            <a href="https://vk.com/ncpti_rnd" target="_blank">
              <FaVk className="social-icons" />
            </a>
            <a href="https://t.me/ncpti" target="_blank">
              <FaTelegram className="social-icons" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCrosnLl1-GRiAYNw6lbhWuw"
              target="_blank"
            >
              <FaYoutube className="social-icons" />
            </a>
          </div>
        </section>
      </div>
      <div className="lower-block">
        <section className="footer__text-info">
          <a href="/politika-konfidenczialnosti.docx" download>
            Политика конфиденциальности
          </a>
          <br />
          <a href="/soglasie.doc">Согласие на обработку персональных данных</a>
          <p className="footer-p__info">
            *Упоминаемые на странице ресурсы Instagram, Facebook, WhatsApp
            принадлежат компании Meta, признанной экстремистской на территории
            Российской Федерации
          </p>
          <p className="footer-p__info">
            «Национальный центр информационного противодействия терроризму и
            экстремизму в образовательной среде и сети Интернет»
          </p>
          <p className="footer-p__info">
            СМИ «Веб-сайт НЦПТИ» зарегистрировано в Федеральной службе по
            надзору в сфере связи, информационных технологий и массовых
            коммуникаций (Роскомнадзор) 7 марта 2013 г.
            <br />
            Свидетельство о регистрации Эл № ФС77-53109
            <br />
            Учредитель — ФГАНУ НИИ «Спецвузавтоматика»
            <br />
            Главный редактор — Быкадорова Александра Сергеевна, email:
            asbyk@ncpti.ru
            <br />
            Адрес редакции: 344011, Ростов-на-Дону, ул. Города Волос, д.6.
            <br />
            Тел. редакции (863) 201-28-22
            <br />
            Настоящий ресурс может содержать материалы 18+
          </p>
          <p>
            Все права на размещенную на сайте информацию принадлежат
            Национальному центру информационного противодействия терроризму и
            экстремизму в образовательной среде и сети Интернет. Материалы могут
            быть использованы только с обязательным размещением активной ссылки
            на первоисточник.
          </p>
        </section>
      </div>
      </div>
    </div>
  );
};

export default Footer;
