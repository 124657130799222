import React from "react";
import "./header.scss";
import Logo from "../../assets/logo-header.png";
import {
  FaTelegram,
  FaVk,
  FaYoutube,
  FaDoorClosed,
  FaDoorOpen,
  FaRegUserCircle,
  FaSignInAlt
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { baseUrl } from "../../constants/constants";
import { useAuth } from "../authProvider/authProvider";

const Header = () => {
  const authContext = useAuth();
  const access = localStorage.getItem("access");

  return (
    <div className="header">
      <div className='header-main'>
      <div className="header__logo">
        <Link to="/" className="header__logo-link" title="На главную">
          <img src={Logo} alt="Логотип НЦПТИ" className="header__logo-img" />
        </Link>
      </div>
      <div className="header__center">
        {/*<search*/}
        {/*    role="search"*/}
        {/*    className='header__center-search'*/}
        {/*>*/}
        {/*    <input*/}
        {/*        type="text"*/}
        {/*        placeholder='Поиск...'*/}
        {/*        className='header__center-input'*/}
        {/*    />*/}
        {/*</search>*/}

      </div>
      <section className="header__social-links">
        <div className="header__social-links-blog">
          <Link to="news">
            <button className="button header__btn">События недели</button>
          </Link>
        </div>
        <div>
          <a href="https://ncpti.ru/umcz/" target='_blank'>
            <button className="button header__btn">Сеть центров</button>
          </a>
        </div>
        <div>
          <a href="https://vk.com/ncpti_rnd" target="_blank" title="Наш ВК">
            <FaVk className="social-icon" />
          </a>
        <a
          href="https://www.youtube.com/channel/UCrosnLl1-GRiAYNw6lbhWuw"
          target="_blank"
          title="Наш YouTube-канал"
        >
          <FaYoutube className="social-icon" />
        </a>
        <a href="https://t.me/ncpti" target="_blank" title="Наш телеграм">
          <FaTelegram className="social-icon" />
        </a>
        {authContext?.authState.isAuth ? (
          <>
            <Link to="account" title="Личный кабинет">
              <FaRegUserCircle className="social-icon" />
            </Link>
          </>
        ) : (
          <Link to="/login" title="Войти">
            <FaSignInAlt className="social-icon" />
          </Link>
        )}
        </div>
      </section>
      </div>
    </div>
  );
};

export default Header;
