import React, { useEffect, useState } from "react";
import CreateNewForum from "./components/CreateNewForum/CreateNewForum";
import ForumsList from "./components/ForumsList/ForumsList";
import "./forumsSettings.scss";
import {baseUrl} from "../../../../constants/constants";
import Modal from "../../../../components/modal/modal";
import {successToast} from "../../../../shared/toastify";

export interface IVisitFormat {
  id: number;
  name: "offline" | "online";
}

export interface IForum {
  id: number;
  name: string;
  description: string;
  supplement: string;
  forum_location: string;
  forum_visit_format: IVisitFormat[];
  start_date_training: string;
  end_date_training: string;
  is_archive: boolean;
  is_active: boolean;
  image_url: string;
}

const ForumsSettingsPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [forums, setForums] = useState<IForum[]>([]);

  async function deleteCurrentForum(id: number) {
    try {
      const res = await fetch(`${baseUrl}forum/${id}`, {
        method: "DELETE",
      });
      successToast("Мероприятие удалено!")
      return res.ok;
    } catch (err) {
      console.log(err);
    }
  }
  const delForum = (id: number) => {
    setForums(forums.filter((forums) => forums.id !== id));
  };

  const updateForumStatus = (currentForum: IForum) => {
    deleteCurrentForum(currentForum.id);
    setForums([...forums, { ...currentForum, is_archive: false }]);
    delForum(currentForum.id);
  };

  useEffect(() => {
    fetch(`${baseUrl}forum`)
      .then((res) => res.json())
      .then((data) => {
        setForums(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <h1 className="admin-title__h1">Редактирование секции "Мероприятия"</h1>
      <div className="admin-buttons">
        <p className="admin-title__p">Создать мероприятие:</p>
        <button className="button admin-btn" onClick={() => setShowModal(true)}>
          Добавить
        </button>
        {showModal && (
          <Modal
            active={showModal}
            title={"Создание мероприятия"}
            сlose={closeModal}
          >
            <CreateNewForum close={closeModal} />
          </Modal>
        )}
      </div>

      {/*Список форумов*/}
      <h2 className="admin-title__h2">Список мероприятий:</h2>
      <div className="admin-div">
        {forums.map((item) => (
          <ForumsList
            key={item.id}
            forums_list={item}
            deleteForum={updateForumStatus}
          />
        ))}
      </div>
    </>
  );
};

export default ForumsSettingsPage;